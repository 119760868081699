import { OidcSecure, useOidc } from '@axa-fr/react-oidc';
import { Suspense, lazy } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { ModelsUserResponseV2Role } from '#edsn/api/idm-bff';
import Fallback from './fallback/Fallback';
import Layout from './layout/Layout';
import { useProfile } from './pages/profile/useProfile';

const Profile = lazy(() => import('./pages/profile/Profile'));

const Users = lazy(() => import('./pages/users/Users'));
const AddUser = lazy(() => import('./pages/users/add-user/AddUser'));
const EditUser = lazy(() => import('./pages/users/edit-user/EditUser'));

const Organizations = lazy(() => import('./pages/organizations/Organizations'));
const Organization = lazy(() => import('./pages/organizations/organization/Organization'));

const ServiceAccounts = lazy(() => import('./pages/service-accounts/ServiceAccounts'));
const AddServiceAccount = lazy(() => import('./pages/service-accounts/add-service-account/AddServiceAccount'));
const EditServiceAccount = lazy(() => import('./pages/service-accounts/edit-service-account/EditServiceAccount'));

const ApplicationRoles = lazy(() => import('./pages/application-roles/ApplicationRoles'));
const ApplicationRole = lazy(() => import('./pages/application-roles/application-role/ApplicationRole'));

const Documentation = lazy(() => import('./documentation/Documentation'));
const DocumentationServiceAccount = lazy(() => import('./documentation/service-account.md'));
const DocumentationOauthServiceAccount = lazy(() => import('./documentation/oauth-service-account/index.md'));
const CreateOauthServiceAccount = lazy(() => import('./documentation/oauth-service-account/aanmaken.md'));
const UseOauthServiceAccount = lazy(() => import('./documentation/oauth-service-account/gebruiken.md'));
const PublicJwks = lazy(() => import('./documentation/oauth-service-account/public-key-jwks.md'));
const Certificates = lazy(() => import('./documentation/oauth-service-account/certificaten.md'));
const WhatIsJwks = lazy(() => import('./documentation/oauth-service-account/wat-is-een-jwks.md'));
const SftpServiceAccount = lazy(() => import('./documentation/sftp-service-account/index.md'));
const CreateSftpServiceAccount = lazy(() => import('./documentation/sftp-service-account/aanmaken.md'));

const LoginHelpLayout = lazy(() => import('./pages/login-help/LoginHelpLayout'));
const LoginHelpPage = lazy(() => import('./pages/login-help/login-help.md'));

const Home = lazy(() => import('./pages/home/Home'));

export function CiamRoutes() {
  const { isAuthenticated } = useOidc();
  const { data, isSuccess } = useProfile();

  // We explicitly do not use the createRoutes function here.
  return (
    <BrowserRouter future={{ v7_startTransition: true }}>
      <Suspense fallback={null}>
        <Routes>
          <Route
            element={
              <Layout>
                <Suspense fallback={null}>
                  <ErrorBoundary FallbackComponent={Fallback}>
                    <Outlet />
                  </ErrorBoundary>
                </Suspense>
              </Layout>
            }
          >
            <Route path="login-help" element={<LoginHelpLayout />}>
              <Route index element={<LoginHelpPage />} />
            </Route>
            <Route path="oauth/handler" element={null} />
            {!isAuthenticated && <Route path="/" element={<Home />} />}

            {/* The Oidc Secure context must be already mounted when we logout */}
            {/* Otherwise, we can potentially end up in a loop. */}
            <Route
              element={
                <OidcSecure>
                  <Outlet />
                </OidcSecure>
              }
            >
              {isAuthenticated && isSuccess && (
                <>
                  <Route path="gegevens" element={<Profile />} />
                  {[
                    ModelsUserResponseV2Role.admin,
                    ModelsUserResponseV2Role.helpdesk,
                    ModelsUserResponseV2Role.operator,
                  ].some(r => r === data.role) && (
                    <Route path="gebruikers">
                      <Route index element={<Users />} />
                      <Route path="toevoegen" element={<AddUser />} />
                      <Route path=":id" element={<EditUser />} />
                    </Route>
                  )}
                  {[
                    ModelsUserResponseV2Role.admin,
                    ModelsUserResponseV2Role.helpdesk,
                    ModelsUserResponseV2Role.operator,
                  ].some(r => r === data.role) && (
                    <Route path="organisaties">
                      <Route index element={<Organizations />} />
                      <Route path=":id" element={<Organization />} />
                    </Route>
                  )}
                  {[ModelsUserResponseV2Role.operator, ModelsUserResponseV2Role.helpdesk].some(
                    r => r === data.role
                  ) && (
                    <Route path="service-accounts">
                      <Route index element={<ServiceAccounts />} />
                      <Route path="toevoegen" element={<AddServiceAccount />} />
                      <Route path=":id" element={<EditServiceAccount />} />
                    </Route>
                  )}
                  {[ModelsUserResponseV2Role.operator, ModelsUserResponseV2Role.helpdesk].some(
                    r => r === data.role
                  ) && (
                    <Route path="rollen">
                      <Route index element={<ApplicationRoles />} />
                      <Route path=":id" element={<ApplicationRole />} />
                    </Route>
                  )}
                  <Route path="documentatie" element={<Documentation />}>
                    <Route index element={<Navigate to="/documentatie/service-account" replace />} />
                    <Route path="service-account" element={<DocumentationServiceAccount />} />
                    <Route path="oauth-service-account">
                      <Route index element={<DocumentationOauthServiceAccount />} />
                      <Route path="aanmaken" element={<CreateOauthServiceAccount />} />
                      <Route path="gebruiken" element={<UseOauthServiceAccount />} />
                      <Route path="public-key-jwks" element={<PublicJwks />} />
                      <Route path="certificaten" element={<Certificates />} />
                      <Route path="wat-is-een-jwks" element={<WhatIsJwks />} />
                    </Route>
                    <Route path="sftp-service-account">
                      <Route index element={<SftpServiceAccount />} />
                      <Route path="aanmaken" element={<CreateSftpServiceAccount />} />
                    </Route>
                  </Route>

                  <Route path="*" element={<AuthenitcatedFallback />} />
                </>
              )}
              {/* This route is added so there is *something* to render when not authenticated */}
              {/* This will cause the parent Route element to mount, causing a login for the catch-all route. */}
              {/* We explicitly render null since this page will show during login */}
              {!isAuthenticated && <Route path="*" element={null} />}
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

function AuthenitcatedFallback() {
  const { data } = useProfile();
  if (
    [ModelsUserResponseV2Role.admin, ModelsUserResponseV2Role.operator, ModelsUserResponseV2Role.helpdesk].some(
      r => r === data?.role
    )
  ) {
    return <Navigate to="/gebruikers" />;
  }

  return <Navigate to="/gegevens" />;
}
