import { Alert, AlertTitle, Box, Button, Typography } from '@mui/material';

export function AuthError() {
  return (
    <Box
      sx={{
        alignItems: { lg: 'center', xs: 'start' },
        background: 'white',
        display: 'flex',
        justifyContent: 'center',
        justifyItems: 'center',
        minHeight: '100vh',
        paddingBottom: 0,
        paddingTop: 0,
        px: 3,
        py: 16,
      }}
    >
      <Alert severity="error" sx={{ display: 'inline-flex' }}>
        <AlertTitle>Kon niet inloggen</AlertTitle>
        <Typography>Het lijkt erop dat onze inlogservice niet beschikbaar is. Probeer het later nog eens.</Typography>
        <Button
          variant="contained"
          color="error"
          sx={{
            mt: 2,
          }}
          href="/"
        >
          Terug
        </Button>
      </Alert>
    </Box>
  );
}
