/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Identity Management
 * The Identity Management API can be used to manage application roles,
users, market participants and service accounts.

## Release notes:
### Version 0.0.1
- Initial release
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  GetMarketParticipantsParams,
  GetOrganizationsParams,
  GetServiceAccountsParams,
  GetServiceAccountsV2Params,
  GetUsersParams,
  GetUsersV2Params,
  ModelsApplicationRoleRequest,
  ModelsApplicationRoleResponse,
  ModelsMarketParticipantResponse,
  ModelsOrganizationRequestBody,
  ModelsOrganizationResponse,
  ModelsPatchIdArrayBody,
  ModelsServiceAccountRequestBody,
  ModelsServiceAccountRequestV2Body,
  ModelsServiceAccountResponse,
  ModelsServiceAccountResponseV2,
  ModelsUserRequestBody,
  ModelsUserRequestV2Body,
  ModelsUserResponse,
  ModelsUserResponseV2,
  ResponsesProblemDetails,
} from './model';
import { fetchInstance } from '../fetchInstance';

/**
 * Get a list of application roles.
 * @summary Get Application Roles
 */
export const getApplicationRoles = (signal?: AbortSignal) => {
  return fetchInstance<ModelsApplicationRoleResponse[]>({ url: `/idm/v1/application-roles`, method: 'GET', signal });
};

export const getGetApplicationRolesQueryKey = () => {
  return [`/idm/v1/application-roles`] as const;
};

export const getGetApplicationRolesQueryOptions = <
  TData = Awaited<ReturnType<typeof getApplicationRoles>>,
  TError = ResponsesProblemDetails | void,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApplicationRoles>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApplicationRolesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApplicationRoles>>> = ({ signal }) =>
    getApplicationRoles(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApplicationRoles>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApplicationRolesQueryResult = NonNullable<Awaited<ReturnType<typeof getApplicationRoles>>>;
export type GetApplicationRolesQueryError = ResponsesProblemDetails | void;

/**
 * @summary Get Application Roles
 */
export const useGetApplicationRoles = <
  TData = Awaited<ReturnType<typeof getApplicationRoles>>,
  TError = ResponsesProblemDetails | void,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApplicationRoles>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApplicationRolesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Add a new market role. Adding a role will make it available for
market participant administrators to assign to their users as they
see fit. Note that these admins can only assign application roles which have
the same market role as their own market participant. e.g. a market participant
with the market role 'LV' can only assign application roles which also have the
market role 'LV'.
 * @summary Add Application Role
 */
export const addApplicationRole = (modelsApplicationRoleRequest: ModelsApplicationRoleRequest) => {
  return fetchInstance<void>({
    url: `/idm/v1/application-roles`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: modelsApplicationRoleRequest,
  });
};

export const getAddApplicationRoleMutationOptions = <
  TError = ResponsesProblemDetails | void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addApplicationRole>>,
    TError,
    { data: ModelsApplicationRoleRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addApplicationRole>>,
  TError,
  { data: ModelsApplicationRoleRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addApplicationRole>>,
    { data: ModelsApplicationRoleRequest }
  > = props => {
    const { data } = props ?? {};

    return addApplicationRole(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddApplicationRoleMutationResult = NonNullable<Awaited<ReturnType<typeof addApplicationRole>>>;
export type AddApplicationRoleMutationBody = ModelsApplicationRoleRequest;
export type AddApplicationRoleMutationError = ResponsesProblemDetails | void;

/**
 * @summary Add Application Role
 */
export const useAddApplicationRole = <TError = ResponsesProblemDetails | void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addApplicationRole>>,
    TError,
    { data: ModelsApplicationRoleRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof addApplicationRole>>,
  TError,
  { data: ModelsApplicationRoleRequest },
  TContext
> => {
  const mutationOptions = getAddApplicationRoleMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Hard delete an application role.
 * @summary Delete Application Role
 */
export const deleteApplicationRole = (id: string) => {
  return fetchInstance<void>({ url: `/idm/v1/application-roles/${id}`, method: 'DELETE' });
};

export const getDeleteApplicationRoleMutationOptions = <
  TError = ResponsesProblemDetails | void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteApplicationRole>>, TError, { id: string }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteApplicationRole>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApplicationRole>>, { id: string }> = props => {
    const { id } = props ?? {};

    return deleteApplicationRole(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteApplicationRoleMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApplicationRole>>>;

export type DeleteApplicationRoleMutationError = ResponsesProblemDetails | void;

/**
 * @summary Delete Application Role
 */
export const useDeleteApplicationRole = <TError = ResponsesProblemDetails | void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteApplicationRole>>, TError, { id: string }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof deleteApplicationRole>>, TError, { id: string }, TContext> => {
  const mutationOptions = getDeleteApplicationRoleMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get a single application role by id.
 * @summary Get Application Role
 */
export const getApplicationRole = (id: string, signal?: AbortSignal) => {
  return fetchInstance<ModelsApplicationRoleResponse>({
    url: `/idm/v1/application-roles/${id}`,
    method: 'GET',
    signal,
  });
};

export const getGetApplicationRoleQueryKey = (id: string) => {
  return [`/idm/v1/application-roles/${id}`] as const;
};

export const getGetApplicationRoleQueryOptions = <
  TData = Awaited<ReturnType<typeof getApplicationRole>>,
  TError = ResponsesProblemDetails | void,
>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApplicationRole>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApplicationRoleQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApplicationRole>>> = ({ signal }) =>
    getApplicationRole(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getApplicationRole>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetApplicationRoleQueryResult = NonNullable<Awaited<ReturnType<typeof getApplicationRole>>>;
export type GetApplicationRoleQueryError = ResponsesProblemDetails | void;

/**
 * @summary Get Application Role
 */
export const useGetApplicationRole = <
  TData = Awaited<ReturnType<typeof getApplicationRole>>,
  TError = ResponsesProblemDetails | void,
>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApplicationRole>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApplicationRoleQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Update a market role, or add a new one with a specific ID.
Adding a role will make it available for
market participant administrators to assign to their users as they
see fit. Note that these admins can only assign application roles which have
the same market role as their own market participant. e.g. a market participant
with the market role 'LV' can only assign application roles which also have the
market role 'LV'.
 * @summary Update Application Role
 */
export const updateApplicationRole = (id: string, modelsApplicationRoleRequest: ModelsApplicationRoleRequest) => {
  return fetchInstance<void>({
    url: `/idm/v1/application-roles/${id}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: modelsApplicationRoleRequest,
  });
};

export const getUpdateApplicationRoleMutationOptions = <
  TError = ResponsesProblemDetails | void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateApplicationRole>>,
    TError,
    { id: string; data: ModelsApplicationRoleRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateApplicationRole>>,
  TError,
  { id: string; data: ModelsApplicationRoleRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateApplicationRole>>,
    { id: string; data: ModelsApplicationRoleRequest }
  > = props => {
    const { id, data } = props ?? {};

    return updateApplicationRole(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateApplicationRoleMutationResult = NonNullable<Awaited<ReturnType<typeof updateApplicationRole>>>;
export type UpdateApplicationRoleMutationBody = ModelsApplicationRoleRequest;
export type UpdateApplicationRoleMutationError = ResponsesProblemDetails | void;

/**
 * @summary Update Application Role
 */
export const useUpdateApplicationRole = <TError = ResponsesProblemDetails | void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateApplicationRole>>,
    TError,
    { id: string; data: ModelsApplicationRoleRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateApplicationRole>>,
  TError,
  { id: string; data: ModelsApplicationRoleRequest },
  TContext
> => {
  const mutationOptions = getUpdateApplicationRoleMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Add or remove service accounts from an application role.
 * @summary Patch Application Role ServiceAccounts
 */
export const patchApplicationRoleServiceAccounts = (id: string, modelsPatchIdArrayBody: ModelsPatchIdArrayBody) => {
  return fetchInstance<void>({
    url: `/idm/v1/application-roles/${id}/service-accounts`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: modelsPatchIdArrayBody,
  });
};

export const getPatchApplicationRoleServiceAccountsMutationOptions = <
  TError = ResponsesProblemDetails | void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchApplicationRoleServiceAccounts>>,
    TError,
    { id: string; data: ModelsPatchIdArrayBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchApplicationRoleServiceAccounts>>,
  TError,
  { id: string; data: ModelsPatchIdArrayBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchApplicationRoleServiceAccounts>>,
    { id: string; data: ModelsPatchIdArrayBody }
  > = props => {
    const { id, data } = props ?? {};

    return patchApplicationRoleServiceAccounts(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchApplicationRoleServiceAccountsMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchApplicationRoleServiceAccounts>>
>;
export type PatchApplicationRoleServiceAccountsMutationBody = ModelsPatchIdArrayBody;
export type PatchApplicationRoleServiceAccountsMutationError = ResponsesProblemDetails | void;

/**
 * @summary Patch Application Role ServiceAccounts
 */
export const usePatchApplicationRoleServiceAccounts = <
  TError = ResponsesProblemDetails | void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchApplicationRoleServiceAccounts>>,
    TError,
    { id: string; data: ModelsPatchIdArrayBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof patchApplicationRoleServiceAccounts>>,
  TError,
  { id: string; data: ModelsPatchIdArrayBody },
  TContext
> => {
  const mutationOptions = getPatchApplicationRoleServiceAccountsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Add or remove users from an application role.
 * @summary Patch Application Role Users
 */
export const patchApplicationRoleUsers = (id: string, modelsPatchIdArrayBody: ModelsPatchIdArrayBody) => {
  return fetchInstance<void>({
    url: `/idm/v1/application-roles/${id}/users`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: modelsPatchIdArrayBody,
  });
};

export const getPatchApplicationRoleUsersMutationOptions = <
  TError = ResponsesProblemDetails | void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchApplicationRoleUsers>>,
    TError,
    { id: string; data: ModelsPatchIdArrayBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchApplicationRoleUsers>>,
  TError,
  { id: string; data: ModelsPatchIdArrayBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchApplicationRoleUsers>>,
    { id: string; data: ModelsPatchIdArrayBody }
  > = props => {
    const { id, data } = props ?? {};

    return patchApplicationRoleUsers(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchApplicationRoleUsersMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchApplicationRoleUsers>>
>;
export type PatchApplicationRoleUsersMutationBody = ModelsPatchIdArrayBody;
export type PatchApplicationRoleUsersMutationError = ResponsesProblemDetails | void;

/**
 * @summary Patch Application Role Users
 */
export const usePatchApplicationRoleUsers = <TError = ResponsesProblemDetails | void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchApplicationRoleUsers>>,
    TError,
    { id: string; data: ModelsPatchIdArrayBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof patchApplicationRoleUsers>>,
  TError,
  { id: string; data: ModelsPatchIdArrayBody },
  TContext
> => {
  const mutationOptions = getPatchApplicationRoleUsersMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get a list of market participants.
 * @deprecated
 * @summary Get Market Participants
 */
export const getMarketParticipants = (params?: GetMarketParticipantsParams, signal?: AbortSignal) => {
  return fetchInstance<ModelsMarketParticipantResponse[]>({
    url: `/idm/v1/market-participants`,
    method: 'GET',
    params,
    signal,
  });
};

export const getGetMarketParticipantsQueryKey = (params?: GetMarketParticipantsParams) => {
  return [`/idm/v1/market-participants`, ...(params ? [params] : [])] as const;
};

export const getGetMarketParticipantsQueryOptions = <
  TData = Awaited<ReturnType<typeof getMarketParticipants>>,
  TError = ResponsesProblemDetails | void,
>(
  params?: GetMarketParticipantsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getMarketParticipants>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMarketParticipantsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMarketParticipants>>> = ({ signal }) =>
    getMarketParticipants(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMarketParticipants>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMarketParticipantsQueryResult = NonNullable<Awaited<ReturnType<typeof getMarketParticipants>>>;
export type GetMarketParticipantsQueryError = ResponsesProblemDetails | void;

/**
 * @deprecated
 * @summary Get Market Participants
 */
export const useGetMarketParticipants = <
  TData = Awaited<ReturnType<typeof getMarketParticipants>>,
  TError = ResponsesProblemDetails | void,
>(
  params?: GetMarketParticipantsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getMarketParticipants>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMarketParticipantsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get a market participant by id.
 * @deprecated
 * @summary Get Market Participant
 */
export const getMarketParticipant = (id: string, signal?: AbortSignal) => {
  return fetchInstance<ModelsMarketParticipantResponse>({
    url: `/idm/v1/market-participants/${id}`,
    method: 'GET',
    signal,
  });
};

export const getGetMarketParticipantQueryKey = (id: string) => {
  return [`/idm/v1/market-participants/${id}`] as const;
};

export const getGetMarketParticipantQueryOptions = <
  TData = Awaited<ReturnType<typeof getMarketParticipant>>,
  TError = ResponsesProblemDetails | void,
>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getMarketParticipant>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMarketParticipantQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMarketParticipant>>> = ({ signal }) =>
    getMarketParticipant(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMarketParticipant>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMarketParticipantQueryResult = NonNullable<Awaited<ReturnType<typeof getMarketParticipant>>>;
export type GetMarketParticipantQueryError = ResponsesProblemDetails | void;

/**
 * @deprecated
 * @summary Get Market Participant
 */
export const useGetMarketParticipant = <
  TData = Awaited<ReturnType<typeof getMarketParticipant>>,
  TError = ResponsesProblemDetails | void,
>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getMarketParticipant>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMarketParticipantQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get a list of service accounts.
 * @deprecated
 * @summary Get ServiceAccounts
 */
export const getServiceAccounts = (params?: GetServiceAccountsParams, signal?: AbortSignal) => {
  return fetchInstance<ModelsServiceAccountResponse[]>({
    url: `/idm/v1/service-accounts`,
    method: 'GET',
    params,
    signal,
  });
};

export const getGetServiceAccountsQueryKey = (params?: GetServiceAccountsParams) => {
  return [`/idm/v1/service-accounts`, ...(params ? [params] : [])] as const;
};

export const getGetServiceAccountsQueryOptions = <
  TData = Awaited<ReturnType<typeof getServiceAccounts>>,
  TError = ResponsesProblemDetails | void,
>(
  params?: GetServiceAccountsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getServiceAccounts>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetServiceAccountsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getServiceAccounts>>> = ({ signal }) =>
    getServiceAccounts(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getServiceAccounts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetServiceAccountsQueryResult = NonNullable<Awaited<ReturnType<typeof getServiceAccounts>>>;
export type GetServiceAccountsQueryError = ResponsesProblemDetails | void;

/**
 * @deprecated
 * @summary Get ServiceAccounts
 */
export const useGetServiceAccounts = <
  TData = Awaited<ReturnType<typeof getServiceAccounts>>,
  TError = ResponsesProblemDetails | void,
>(
  params?: GetServiceAccountsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getServiceAccounts>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetServiceAccountsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Add a service account
 * @deprecated
 * @summary Add ServiceAccount
 */
export const addServiceAccount = (modelsServiceAccountRequestBody: ModelsServiceAccountRequestBody) => {
  return fetchInstance<void>({
    url: `/idm/v1/service-accounts`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: modelsServiceAccountRequestBody,
  });
};

export const getAddServiceAccountMutationOptions = <
  TError = ResponsesProblemDetails | void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addServiceAccount>>,
    TError,
    { data: ModelsServiceAccountRequestBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addServiceAccount>>,
  TError,
  { data: ModelsServiceAccountRequestBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addServiceAccount>>,
    { data: ModelsServiceAccountRequestBody }
  > = props => {
    const { data } = props ?? {};

    return addServiceAccount(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddServiceAccountMutationResult = NonNullable<Awaited<ReturnType<typeof addServiceAccount>>>;
export type AddServiceAccountMutationBody = ModelsServiceAccountRequestBody;
export type AddServiceAccountMutationError = ResponsesProblemDetails | void;

/**
 * @deprecated
 * @summary Add ServiceAccount
 */
export const useAddServiceAccount = <TError = ResponsesProblemDetails | void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addServiceAccount>>,
    TError,
    { data: ModelsServiceAccountRequestBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof addServiceAccount>>,
  TError,
  { data: ModelsServiceAccountRequestBody },
  TContext
> => {
  const mutationOptions = getAddServiceAccountMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Delete a Service Account
 * @deprecated
 * @summary Delete ServiceAccount
 */
export const deleteServiceAccount = (id: string) => {
  return fetchInstance<void>({ url: `/idm/v1/service-accounts/${id}`, method: 'DELETE' });
};

export const getDeleteServiceAccountMutationOptions = <
  TError = ResponsesProblemDetails | void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteServiceAccount>>, TError, { id: string }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteServiceAccount>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteServiceAccount>>, { id: string }> = props => {
    const { id } = props ?? {};

    return deleteServiceAccount(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteServiceAccountMutationResult = NonNullable<Awaited<ReturnType<typeof deleteServiceAccount>>>;

export type DeleteServiceAccountMutationError = ResponsesProblemDetails | void;

/**
 * @deprecated
 * @summary Delete ServiceAccount
 */
export const useDeleteServiceAccount = <TError = ResponsesProblemDetails | void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteServiceAccount>>, TError, { id: string }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof deleteServiceAccount>>, TError, { id: string }, TContext> => {
  const mutationOptions = getDeleteServiceAccountMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get a service account by id
 * @deprecated
 * @summary Get ServiceAccount
 */
export const getServiceAccount = (id: string, signal?: AbortSignal) => {
  return fetchInstance<ModelsServiceAccountResponse>({ url: `/idm/v1/service-accounts/${id}`, method: 'GET', signal });
};

export const getGetServiceAccountQueryKey = (id: string) => {
  return [`/idm/v1/service-accounts/${id}`] as const;
};

export const getGetServiceAccountQueryOptions = <
  TData = Awaited<ReturnType<typeof getServiceAccount>>,
  TError = ResponsesProblemDetails | void,
>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getServiceAccount>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetServiceAccountQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getServiceAccount>>> = ({ signal }) =>
    getServiceAccount(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getServiceAccount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetServiceAccountQueryResult = NonNullable<Awaited<ReturnType<typeof getServiceAccount>>>;
export type GetServiceAccountQueryError = ResponsesProblemDetails | void;

/**
 * @deprecated
 * @summary Get ServiceAccount
 */
export const useGetServiceAccount = <
  TData = Awaited<ReturnType<typeof getServiceAccount>>,
  TError = ResponsesProblemDetails | void,
>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getServiceAccount>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetServiceAccountQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Creates or updates a Service Account with a given ID
 * @deprecated
 * @summary Update ServiceAccount
 */
export const updateServiceAccount = (id: string, modelsServiceAccountRequestBody: ModelsServiceAccountRequestBody) => {
  return fetchInstance<void>({
    url: `/idm/v1/service-accounts/${id}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: modelsServiceAccountRequestBody,
  });
};

export const getUpdateServiceAccountMutationOptions = <
  TError = ResponsesProblemDetails | void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateServiceAccount>>,
    TError,
    { id: string; data: ModelsServiceAccountRequestBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateServiceAccount>>,
  TError,
  { id: string; data: ModelsServiceAccountRequestBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateServiceAccount>>,
    { id: string; data: ModelsServiceAccountRequestBody }
  > = props => {
    const { id, data } = props ?? {};

    return updateServiceAccount(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateServiceAccountMutationResult = NonNullable<Awaited<ReturnType<typeof updateServiceAccount>>>;
export type UpdateServiceAccountMutationBody = ModelsServiceAccountRequestBody;
export type UpdateServiceAccountMutationError = ResponsesProblemDetails | void;

/**
 * @deprecated
 * @summary Update ServiceAccount
 */
export const useUpdateServiceAccount = <TError = ResponsesProblemDetails | void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateServiceAccount>>,
    TError,
    { id: string; data: ModelsServiceAccountRequestBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateServiceAccount>>,
  TError,
  { id: string; data: ModelsServiceAccountRequestBody },
  TContext
> => {
  const mutationOptions = getUpdateServiceAccountMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get a list of users.
 * @deprecated
 * @summary Get Users
 */
export const getUsers = (params?: GetUsersParams, signal?: AbortSignal) => {
  return fetchInstance<ModelsUserResponse[]>({ url: `/idm/v1/users`, method: 'GET', params, signal });
};

export const getGetUsersQueryKey = (params?: GetUsersParams) => {
  return [`/idm/v1/users`, ...(params ? [params] : [])] as const;
};

export const getGetUsersQueryOptions = <
  TData = Awaited<ReturnType<typeof getUsers>>,
  TError = ResponsesProblemDetails | void,
>(
  params?: GetUsersParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUsersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsers>>> = ({ signal }) => getUsers(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUsers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getUsers>>>;
export type GetUsersQueryError = ResponsesProblemDetails | void;

/**
 * @deprecated
 * @summary Get Users
 */
export const useGetUsers = <TData = Awaited<ReturnType<typeof getUsers>>, TError = ResponsesProblemDetails | void>(
  params?: GetUsersParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUsersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Add an user
 * @deprecated
 * @summary Add User
 */
export const addUser = (modelsUserRequestBody: ModelsUserRequestBody) => {
  return fetchInstance<void>({
    url: `/idm/v1/users`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: modelsUserRequestBody,
  });
};

export const getAddUserMutationOptions = <TError = ResponsesProblemDetails | void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof addUser>>, TError, { data: ModelsUserRequestBody }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof addUser>>, TError, { data: ModelsUserRequestBody }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof addUser>>, { data: ModelsUserRequestBody }> = props => {
    const { data } = props ?? {};

    return addUser(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddUserMutationResult = NonNullable<Awaited<ReturnType<typeof addUser>>>;
export type AddUserMutationBody = ModelsUserRequestBody;
export type AddUserMutationError = ResponsesProblemDetails | void;

/**
 * @deprecated
 * @summary Add User
 */
export const useAddUser = <TError = ResponsesProblemDetails | void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof addUser>>, TError, { data: ModelsUserRequestBody }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof addUser>>, TError, { data: ModelsUserRequestBody }, TContext> => {
  const mutationOptions = getAddUserMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Delete an user
 * @deprecated
 * @summary Delete User
 */
export const deleteUser = (id: string) => {
  return fetchInstance<void>({ url: `/idm/v1/users/${id}`, method: 'DELETE' });
};

export const getDeleteUserMutationOptions = <TError = ResponsesProblemDetails | void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteUser>>, TError, { id: string }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteUser>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteUser>>, { id: string }> = props => {
    const { id } = props ?? {};

    return deleteUser(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserMutationResult = NonNullable<Awaited<ReturnType<typeof deleteUser>>>;

export type DeleteUserMutationError = ResponsesProblemDetails | void;

/**
 * @deprecated
 * @summary Delete User
 */
export const useDeleteUser = <TError = ResponsesProblemDetails | void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteUser>>, TError, { id: string }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof deleteUser>>, TError, { id: string }, TContext> => {
  const mutationOptions = getDeleteUserMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get an user by id
 * @deprecated
 * @summary Get User
 */
export const getUser = (id: string, signal?: AbortSignal) => {
  return fetchInstance<ModelsUserResponse>({ url: `/idm/v1/users/${id}`, method: 'GET', signal });
};

export const getGetUserQueryKey = (id: string) => {
  return [`/idm/v1/users/${id}`] as const;
};

export const getGetUserQueryOptions = <
  TData = Awaited<ReturnType<typeof getUser>>,
  TError = ResponsesProblemDetails | void,
>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUser>>> = ({ signal }) => getUser(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUser>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserQueryResult = NonNullable<Awaited<ReturnType<typeof getUser>>>;
export type GetUserQueryError = ResponsesProblemDetails | void;

/**
 * @deprecated
 * @summary Get User
 */
export const useGetUser = <TData = Awaited<ReturnType<typeof getUser>>, TError = ResponsesProblemDetails | void>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Update a user
 * @deprecated
 * @summary Update User
 */
export const updateUser = (id: string, modelsUserRequestBody: ModelsUserRequestBody) => {
  return fetchInstance<void>({
    url: `/idm/v1/users/${id}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: modelsUserRequestBody,
  });
};

export const getUpdateUserMutationOptions = <TError = ResponsesProblemDetails | void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUser>>,
    TError,
    { id: string; data: ModelsUserRequestBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateUser>>,
  TError,
  { id: string; data: ModelsUserRequestBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateUser>>,
    { id: string; data: ModelsUserRequestBody }
  > = props => {
    const { id, data } = props ?? {};

    return updateUser(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateUserMutationResult = NonNullable<Awaited<ReturnType<typeof updateUser>>>;
export type UpdateUserMutationBody = ModelsUserRequestBody;
export type UpdateUserMutationError = ResponsesProblemDetails | void;

/**
 * @deprecated
 * @summary Update User
 */
export const useUpdateUser = <TError = ResponsesProblemDetails | void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUser>>,
    TError,
    { id: string; data: ModelsUserRequestBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateUser>>,
  TError,
  { id: string; data: ModelsUserRequestBody },
  TContext
> => {
  const mutationOptions = getUpdateUserMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get a list of organizations.
 * @summary Get Organizations
 */
export const getOrganizations = (params?: GetOrganizationsParams, signal?: AbortSignal) => {
  return fetchInstance<ModelsOrganizationResponse[]>({ url: `/idm/v2/organizations`, method: 'GET', params, signal });
};

export const getGetOrganizationsQueryKey = (params?: GetOrganizationsParams) => {
  return [`/idm/v2/organizations`, ...(params ? [params] : [])] as const;
};

export const getGetOrganizationsQueryOptions = <
  TData = Awaited<ReturnType<typeof getOrganizations>>,
  TError = ResponsesProblemDetails | void,
>(
  params?: GetOrganizationsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getOrganizations>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOrganizationsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrganizations>>> = ({ signal }) =>
    getOrganizations(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getOrganizations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetOrganizationsQueryResult = NonNullable<Awaited<ReturnType<typeof getOrganizations>>>;
export type GetOrganizationsQueryError = ResponsesProblemDetails | void;

/**
 * @summary Get Organizations
 */
export const useGetOrganizations = <
  TData = Awaited<ReturnType<typeof getOrganizations>>,
  TError = ResponsesProblemDetails | void,
>(
  params?: GetOrganizationsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getOrganizations>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetOrganizationsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Add a organization.
 * @summary Add Organization
 */
export const addOrganization = (modelsOrganizationRequestBody: ModelsOrganizationRequestBody) => {
  return fetchInstance<void>({
    url: `/idm/v2/organizations`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: modelsOrganizationRequestBody,
  });
};

export const getAddOrganizationMutationOptions = <
  TError = ResponsesProblemDetails | void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addOrganization>>,
    TError,
    { data: ModelsOrganizationRequestBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addOrganization>>,
  TError,
  { data: ModelsOrganizationRequestBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addOrganization>>,
    { data: ModelsOrganizationRequestBody }
  > = props => {
    const { data } = props ?? {};

    return addOrganization(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddOrganizationMutationResult = NonNullable<Awaited<ReturnType<typeof addOrganization>>>;
export type AddOrganizationMutationBody = ModelsOrganizationRequestBody;
export type AddOrganizationMutationError = ResponsesProblemDetails | void;

/**
 * @summary Add Organization
 */
export const useAddOrganization = <TError = ResponsesProblemDetails | void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addOrganization>>,
    TError,
    { data: ModelsOrganizationRequestBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof addOrganization>>,
  TError,
  { data: ModelsOrganizationRequestBody },
  TContext
> => {
  const mutationOptions = getAddOrganizationMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Delete a organization.
 * @summary Delete Organization
 */
export const deleteOrganization = (id: string) => {
  return fetchInstance<void>({ url: `/idm/v2/organizations/${id}`, method: 'DELETE' });
};

export const getDeleteOrganizationMutationOptions = <
  TError = ResponsesProblemDetails | void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteOrganization>>, TError, { id: string }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteOrganization>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteOrganization>>, { id: string }> = props => {
    const { id } = props ?? {};

    return deleteOrganization(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteOrganizationMutationResult = NonNullable<Awaited<ReturnType<typeof deleteOrganization>>>;

export type DeleteOrganizationMutationError = ResponsesProblemDetails | void;

/**
 * @summary Delete Organization
 */
export const useDeleteOrganization = <TError = ResponsesProblemDetails | void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteOrganization>>, TError, { id: string }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof deleteOrganization>>, TError, { id: string }, TContext> => {
  const mutationOptions = getDeleteOrganizationMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get a organization by id.
 * @summary Get Organization
 */
export const getOrganization = (id: string, signal?: AbortSignal) => {
  return fetchInstance<ModelsOrganizationResponse>({ url: `/idm/v2/organizations/${id}`, method: 'GET', signal });
};

export const getGetOrganizationQueryKey = (id: string) => {
  return [`/idm/v2/organizations/${id}`] as const;
};

export const getGetOrganizationQueryOptions = <
  TData = Awaited<ReturnType<typeof getOrganization>>,
  TError = ResponsesProblemDetails | void,
>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getOrganization>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOrganizationQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrganization>>> = ({ signal }) =>
    getOrganization(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getOrganization>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetOrganizationQueryResult = NonNullable<Awaited<ReturnType<typeof getOrganization>>>;
export type GetOrganizationQueryError = ResponsesProblemDetails | void;

/**
 * @summary Get Organization
 */
export const useGetOrganization = <
  TData = Awaited<ReturnType<typeof getOrganization>>,
  TError = ResponsesProblemDetails | void,
>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getOrganization>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetOrganizationQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Update a organization by id. Or create it if it doesn't exist.
 * @summary Update Organization
 */
export const updateOrganization = (id: string, modelsOrganizationRequestBody: ModelsOrganizationRequestBody) => {
  return fetchInstance<void>({
    url: `/idm/v2/organizations/${id}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: modelsOrganizationRequestBody,
  });
};

export const getUpdateOrganizationMutationOptions = <
  TError = ResponsesProblemDetails | void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateOrganization>>,
    TError,
    { id: string; data: ModelsOrganizationRequestBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateOrganization>>,
  TError,
  { id: string; data: ModelsOrganizationRequestBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateOrganization>>,
    { id: string; data: ModelsOrganizationRequestBody }
  > = props => {
    const { id, data } = props ?? {};

    return updateOrganization(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateOrganizationMutationResult = NonNullable<Awaited<ReturnType<typeof updateOrganization>>>;
export type UpdateOrganizationMutationBody = ModelsOrganizationRequestBody;
export type UpdateOrganizationMutationError = ResponsesProblemDetails | void;

/**
 * @summary Update Organization
 */
export const useUpdateOrganization = <TError = ResponsesProblemDetails | void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateOrganization>>,
    TError,
    { id: string; data: ModelsOrganizationRequestBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateOrganization>>,
  TError,
  { id: string; data: ModelsOrganizationRequestBody },
  TContext
> => {
  const mutationOptions = getUpdateOrganizationMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get a list of service accounts.
 * @summary Get ServiceAccounts
 */
export const getServiceAccountsV2 = (params?: GetServiceAccountsV2Params, signal?: AbortSignal) => {
  return fetchInstance<ModelsServiceAccountResponseV2[]>({
    url: `/idm/v2/service-accounts`,
    method: 'GET',
    params,
    signal,
  });
};

export const getGetServiceAccountsV2QueryKey = (params?: GetServiceAccountsV2Params) => {
  return [`/idm/v2/service-accounts`, ...(params ? [params] : [])] as const;
};

export const getGetServiceAccountsV2QueryOptions = <
  TData = Awaited<ReturnType<typeof getServiceAccountsV2>>,
  TError = ResponsesProblemDetails | void,
>(
  params?: GetServiceAccountsV2Params,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getServiceAccountsV2>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetServiceAccountsV2QueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getServiceAccountsV2>>> = ({ signal }) =>
    getServiceAccountsV2(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getServiceAccountsV2>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetServiceAccountsV2QueryResult = NonNullable<Awaited<ReturnType<typeof getServiceAccountsV2>>>;
export type GetServiceAccountsV2QueryError = ResponsesProblemDetails | void;

/**
 * @summary Get ServiceAccounts
 */
export const useGetServiceAccountsV2 = <
  TData = Awaited<ReturnType<typeof getServiceAccountsV2>>,
  TError = ResponsesProblemDetails | void,
>(
  params?: GetServiceAccountsV2Params,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getServiceAccountsV2>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetServiceAccountsV2QueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Add a service account
 * @summary Add ServiceAccount
 */
export const addServiceAccountV2 = (modelsServiceAccountRequestV2Body: ModelsServiceAccountRequestV2Body) => {
  return fetchInstance<void>({
    url: `/idm/v2/service-accounts`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: modelsServiceAccountRequestV2Body,
  });
};

export const getAddServiceAccountV2MutationOptions = <
  TError = ResponsesProblemDetails | void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addServiceAccountV2>>,
    TError,
    { data: ModelsServiceAccountRequestV2Body },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addServiceAccountV2>>,
  TError,
  { data: ModelsServiceAccountRequestV2Body },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addServiceAccountV2>>,
    { data: ModelsServiceAccountRequestV2Body }
  > = props => {
    const { data } = props ?? {};

    return addServiceAccountV2(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddServiceAccountV2MutationResult = NonNullable<Awaited<ReturnType<typeof addServiceAccountV2>>>;
export type AddServiceAccountV2MutationBody = ModelsServiceAccountRequestV2Body;
export type AddServiceAccountV2MutationError = ResponsesProblemDetails | void;

/**
 * @summary Add ServiceAccount
 */
export const useAddServiceAccountV2 = <TError = ResponsesProblemDetails | void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addServiceAccountV2>>,
    TError,
    { data: ModelsServiceAccountRequestV2Body },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof addServiceAccountV2>>,
  TError,
  { data: ModelsServiceAccountRequestV2Body },
  TContext
> => {
  const mutationOptions = getAddServiceAccountV2MutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Delete a Service Account
 * @summary Delete ServiceAccount
 */
export const deleteServiceAccountV2 = (id: string) => {
  return fetchInstance<void>({ url: `/idm/v2/service-accounts/${id}`, method: 'DELETE' });
};

export const getDeleteServiceAccountV2MutationOptions = <
  TError = ResponsesProblemDetails | void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteServiceAccountV2>>, TError, { id: string }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteServiceAccountV2>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteServiceAccountV2>>, { id: string }> = props => {
    const { id } = props ?? {};

    return deleteServiceAccountV2(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteServiceAccountV2MutationResult = NonNullable<Awaited<ReturnType<typeof deleteServiceAccountV2>>>;

export type DeleteServiceAccountV2MutationError = ResponsesProblemDetails | void;

/**
 * @summary Delete ServiceAccount
 */
export const useDeleteServiceAccountV2 = <TError = ResponsesProblemDetails | void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteServiceAccountV2>>, TError, { id: string }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof deleteServiceAccountV2>>, TError, { id: string }, TContext> => {
  const mutationOptions = getDeleteServiceAccountV2MutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get a service account by id
 * @summary Get ServiceAccount
 */
export const getServiceAccountV2 = (id: string, signal?: AbortSignal) => {
  return fetchInstance<ModelsServiceAccountResponseV2>({
    url: `/idm/v2/service-accounts/${id}`,
    method: 'GET',
    signal,
  });
};

export const getGetServiceAccountV2QueryKey = (id: string) => {
  return [`/idm/v2/service-accounts/${id}`] as const;
};

export const getGetServiceAccountV2QueryOptions = <
  TData = Awaited<ReturnType<typeof getServiceAccountV2>>,
  TError = ResponsesProblemDetails | void,
>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getServiceAccountV2>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetServiceAccountV2QueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getServiceAccountV2>>> = ({ signal }) =>
    getServiceAccountV2(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getServiceAccountV2>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetServiceAccountV2QueryResult = NonNullable<Awaited<ReturnType<typeof getServiceAccountV2>>>;
export type GetServiceAccountV2QueryError = ResponsesProblemDetails | void;

/**
 * @summary Get ServiceAccount
 */
export const useGetServiceAccountV2 = <
  TData = Awaited<ReturnType<typeof getServiceAccountV2>>,
  TError = ResponsesProblemDetails | void,
>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getServiceAccountV2>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetServiceAccountV2QueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Creates or updates a Service Account with a given ID
 * @summary Update ServiceAccount
 */
export const updateServiceAccountV2 = (
  id: string,
  modelsServiceAccountRequestV2Body: ModelsServiceAccountRequestV2Body
) => {
  return fetchInstance<void>({
    url: `/idm/v2/service-accounts/${id}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: modelsServiceAccountRequestV2Body,
  });
};

export const getUpdateServiceAccountV2MutationOptions = <
  TError = ResponsesProblemDetails | void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateServiceAccountV2>>,
    TError,
    { id: string; data: ModelsServiceAccountRequestV2Body },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateServiceAccountV2>>,
  TError,
  { id: string; data: ModelsServiceAccountRequestV2Body },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateServiceAccountV2>>,
    { id: string; data: ModelsServiceAccountRequestV2Body }
  > = props => {
    const { id, data } = props ?? {};

    return updateServiceAccountV2(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateServiceAccountV2MutationResult = NonNullable<Awaited<ReturnType<typeof updateServiceAccountV2>>>;
export type UpdateServiceAccountV2MutationBody = ModelsServiceAccountRequestV2Body;
export type UpdateServiceAccountV2MutationError = ResponsesProblemDetails | void;

/**
 * @summary Update ServiceAccount
 */
export const useUpdateServiceAccountV2 = <TError = ResponsesProblemDetails | void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateServiceAccountV2>>,
    TError,
    { id: string; data: ModelsServiceAccountRequestV2Body },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateServiceAccountV2>>,
  TError,
  { id: string; data: ModelsServiceAccountRequestV2Body },
  TContext
> => {
  const mutationOptions = getUpdateServiceAccountV2MutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get a list of users.
 * @summary Get Users
 */
export const getUsersV2 = (params?: GetUsersV2Params, signal?: AbortSignal) => {
  return fetchInstance<ModelsUserResponseV2[]>({ url: `/idm/v2/users`, method: 'GET', params, signal });
};

export const getGetUsersV2QueryKey = (params?: GetUsersV2Params) => {
  return [`/idm/v2/users`, ...(params ? [params] : [])] as const;
};

export const getGetUsersV2QueryOptions = <
  TData = Awaited<ReturnType<typeof getUsersV2>>,
  TError = ResponsesProblemDetails | void,
>(
  params?: GetUsersV2Params,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getUsersV2>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUsersV2QueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersV2>>> = ({ signal }) => getUsersV2(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUsersV2>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUsersV2QueryResult = NonNullable<Awaited<ReturnType<typeof getUsersV2>>>;
export type GetUsersV2QueryError = ResponsesProblemDetails | void;

/**
 * @summary Get Users
 */
export const useGetUsersV2 = <TData = Awaited<ReturnType<typeof getUsersV2>>, TError = ResponsesProblemDetails | void>(
  params?: GetUsersV2Params,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getUsersV2>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUsersV2QueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Add an user
 * @summary Add User
 */
export const addUserV2 = (modelsUserRequestV2Body: ModelsUserRequestV2Body) => {
  return fetchInstance<void>({
    url: `/idm/v2/users`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: modelsUserRequestV2Body,
  });
};

export const getAddUserV2MutationOptions = <TError = ResponsesProblemDetails | void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addUserV2>>,
    TError,
    { data: ModelsUserRequestV2Body },
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<typeof addUserV2>>, TError, { data: ModelsUserRequestV2Body }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addUserV2>>,
    { data: ModelsUserRequestV2Body }
  > = props => {
    const { data } = props ?? {};

    return addUserV2(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddUserV2MutationResult = NonNullable<Awaited<ReturnType<typeof addUserV2>>>;
export type AddUserV2MutationBody = ModelsUserRequestV2Body;
export type AddUserV2MutationError = ResponsesProblemDetails | void;

/**
 * @summary Add User
 */
export const useAddUserV2 = <TError = ResponsesProblemDetails | void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addUserV2>>,
    TError,
    { data: ModelsUserRequestV2Body },
    TContext
  >;
}): UseMutationResult<Awaited<ReturnType<typeof addUserV2>>, TError, { data: ModelsUserRequestV2Body }, TContext> => {
  const mutationOptions = getAddUserV2MutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Delete an user
 * @summary Delete User
 */
export const deleteUserV2 = (id: string) => {
  return fetchInstance<void>({ url: `/idm/v2/users/${id}`, method: 'DELETE' });
};

export const getDeleteUserV2MutationOptions = <TError = ResponsesProblemDetails | void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteUserV2>>, TError, { id: string }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteUserV2>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteUserV2>>, { id: string }> = props => {
    const { id } = props ?? {};

    return deleteUserV2(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserV2MutationResult = NonNullable<Awaited<ReturnType<typeof deleteUserV2>>>;

export type DeleteUserV2MutationError = ResponsesProblemDetails | void;

/**
 * @summary Delete User
 */
export const useDeleteUserV2 = <TError = ResponsesProblemDetails | void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteUserV2>>, TError, { id: string }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof deleteUserV2>>, TError, { id: string }, TContext> => {
  const mutationOptions = getDeleteUserV2MutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get an user by id
 * @summary Get User
 */
export const getUserV2 = (id: string, signal?: AbortSignal) => {
  return fetchInstance<ModelsUserResponseV2>({ url: `/idm/v2/users/${id}`, method: 'GET', signal });
};

export const getGetUserV2QueryKey = (id: string) => {
  return [`/idm/v2/users/${id}`] as const;
};

export const getGetUserV2QueryOptions = <
  TData = Awaited<ReturnType<typeof getUserV2>>,
  TError = ResponsesProblemDetails | void,
>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getUserV2>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserV2QueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserV2>>> = ({ signal }) => getUserV2(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserV2>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserV2QueryResult = NonNullable<Awaited<ReturnType<typeof getUserV2>>>;
export type GetUserV2QueryError = ResponsesProblemDetails | void;

/**
 * @summary Get User
 */
export const useGetUserV2 = <TData = Awaited<ReturnType<typeof getUserV2>>, TError = ResponsesProblemDetails | void>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getUserV2>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserV2QueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Update a user
 * @summary Update User
 */
export const updateUserV2 = (id: string, modelsUserRequestV2Body: ModelsUserRequestV2Body) => {
  return fetchInstance<void>({
    url: `/idm/v2/users/${id}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: modelsUserRequestV2Body,
  });
};

export const getUpdateUserV2MutationOptions = <TError = ResponsesProblemDetails | void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserV2>>,
    TError,
    { id: string; data: ModelsUserRequestV2Body },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateUserV2>>,
  TError,
  { id: string; data: ModelsUserRequestV2Body },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateUserV2>>,
    { id: string; data: ModelsUserRequestV2Body }
  > = props => {
    const { id, data } = props ?? {};

    return updateUserV2(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateUserV2MutationResult = NonNullable<Awaited<ReturnType<typeof updateUserV2>>>;
export type UpdateUserV2MutationBody = ModelsUserRequestV2Body;
export type UpdateUserV2MutationError = ResponsesProblemDetails | void;

/**
 * @summary Update User
 */
export const useUpdateUserV2 = <TError = ResponsesProblemDetails | void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserV2>>,
    TError,
    { id: string; data: ModelsUserRequestV2Body },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateUserV2>>,
  TError,
  { id: string; data: ModelsUserRequestV2Body },
  TContext
> => {
  const mutationOptions = getUpdateUserV2MutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Invite user again
 * @summary Reinvite
 */
export const reinviteV2 = (id: string) => {
  return fetchInstance<void>({ url: `/idm/v2/users/${id}/reinvite`, method: 'POST' });
};

export const getReinviteV2MutationOptions = <TError = ResponsesProblemDetails | void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof reinviteV2>>, TError, { id: string }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof reinviteV2>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof reinviteV2>>, { id: string }> = props => {
    const { id } = props ?? {};

    return reinviteV2(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type ReinviteV2MutationResult = NonNullable<Awaited<ReturnType<typeof reinviteV2>>>;

export type ReinviteV2MutationError = ResponsesProblemDetails | void;

/**
 * @summary Reinvite
 */
export const useReinviteV2 = <TError = ResponsesProblemDetails | void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof reinviteV2>>, TError, { id: string }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof reinviteV2>>, TError, { id: string }, TContext> => {
  const mutationOptions = getReinviteV2MutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Reset the users MFA devices
 * @summary Reset MFA
 */
export const resetmfaV2 = (id: string) => {
  return fetchInstance<void>({ url: `/idm/v2/users/${id}/resetmfa`, method: 'POST' });
};

export const getResetmfaV2MutationOptions = <TError = ResponsesProblemDetails | void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof resetmfaV2>>, TError, { id: string }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof resetmfaV2>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof resetmfaV2>>, { id: string }> = props => {
    const { id } = props ?? {};

    return resetmfaV2(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResetmfaV2MutationResult = NonNullable<Awaited<ReturnType<typeof resetmfaV2>>>;

export type ResetmfaV2MutationError = ResponsesProblemDetails | void;

/**
 * @summary Reset MFA
 */
export const useResetmfaV2 = <TError = ResponsesProblemDetails | void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof resetmfaV2>>, TError, { id: string }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof resetmfaV2>>, TError, { id: string }, TContext> => {
  const mutationOptions = getResetmfaV2MutationOptions(options);

  return useMutation(mutationOptions);
};
