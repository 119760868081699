import { ModelsServiceAccountResponseV2 } from './generated/model';

export const createServiceAccounts = (): ModelsServiceAccountResponseV2[] => [
  {
    id: 'f7ad0fb0-af7f-4fb8-b3ff-1693c0dab48a',
    name: 'nmzDeqAwbzn7Rc2j',
    description: 'my-test-account',
    type: 'oauth',
    oauthConfig: {
      jwks: { keys: [] },
      jwksUri: 'https://dev.idp.cmf.energysector.nl/am/oauth2/connect/jwk_uri',
    },
    sftpConfig: {
      sshKeys: [],
    },
    applicationRoles: [
      {
        id: '44980820-b054-4966-86f6-a6ff6d5afe43',
        marketRoles: [],
        name: 'filegateway:ui:write',
        description: 'Write-access to the File Gateway UI.',
      },
    ],
    organizations: [
      {
        externalId: '',
        id: 'eeca3b4a-f548-4ed2-970f-286166cc9b12',
        name: 'Enrgy Included B.V.',
        ean13: '88163718371893',
        marketRole: 'EDSN',
        parentId: '0bbc2424-4faa-4c5c-92d3-0c78192953c0',
        organizationType: 'subunit',
        status: 'ACTIVE',
        organizationStructure: {
          externalId: '',
          id: '',
          name: '',
          registrationNumber: '94712623',
          nameFacade: '',
          registrationNumberOrigin: '',
          marketParticipants: [],
        },
      },
    ],
  },
  {
    id: '554824cc-5b97-426e-9158-1ab83f19764d',
    name: 'nmzDeqAwbzn7Rc2j',
    description: 'my-test-account-2',
    type: 'oauth',
    oauthConfig: {
      jwksUri: 'https://dev.idp.cmf.energysector.nl/am/oauth2/connect/jwk_uri',
      jwks: {
        keys: [
          {
            alg: 'RS256',
            e: 'AQAB',
            kty: 'RSA',
            n: 'sFb6ZhDnyZJC5VqXT1mEVUm98rfclCbodUnPc9pYMYc2AcBDG4029k_Wj2xFK9mN9HSCT99-QyJsDi_j-fiFPIe5ZSo6ovHrgeSrROgAd7zyoYw617wUbV39igufzXan01dXlO0T-kUYviji2OnPE7HN3reECJlkElM305Q_yj2aUus3KgqeRaNDKRWf7mWzZGLep44t8Eeng-A9EoVEPHq21FHoQFklbKIi0NrdUtVw5AROocWkZB2m1uwRyCk0xkV8QyyyRxvNR79K4iu1ifIu3gIizM7gXxh5OM4aZPqMgPidRqE-bZ2qWNMk8QWoCrccc76acQOwkHD0Y1-4vMWW5cspBwJ7wTgeiUpbQo6K7VZvJbScT3dmAwrLYTpGU5qGg9I-LwVtcYucxRuE1oBIqnFsLk1-MpIm844f6WY6vDylcjF5JBlqfsWLeukIsz_B56IbYpzDIM7tWcQ4PV5z602XGetcXYhsOafDyWJhVq9P3FiBsyaB4zqOvuc3bNVYoVnMtwIOWgRUOSK_GGlsB-BDfPL2bzWsTP9wMtqSG9c47Zijat4BDrillgNDf3HKOiLIeBEVjsjD1XaVt8DhWJ6M_EXb3q5dAC2aqTRE_sY8c2P8jfeRan6yk7W5f8COJDvqRIsDfjSepme3RnIhaz51-WGOeweYbdDUt_k',
          },
        ],
      },
    },
    applicationRoles: [
      {
        id: '060cf81d-936e-47b3-8b50-cbbc41e4dec6',
        marketRoles: [],
        name: 'cmp:application:write',
        description: 'Allows access to C-MP and the ability to modify data.',
      },
    ],
    organizations: [
      {
        externalId: '',
        id: 'eeca3b4a-f548-4ed2-970f-286166cc9b12',
        name: 'Enrgy Included B.V.',
        ean13: '88163718371893',
        marketRole: 'EDSN',
        parentId: '0bbc2424-4faa-4c5c-92d3-0c78192953c0',
        organizationType: 'subunit',
        status: 'ACTIVE',
        organizationStructure: {
          externalId: '',
          id: '',
          name: '',
          registrationNumber: '94712623',
          nameFacade: '',
          registrationNumberOrigin: '',
          marketParticipants: [],
        },
      },
    ],
  },
  {
    id: 'acea9173-f3a0-413c-a860-a013fa946073',
    name: 'nmzDeqAwbzn7Rc2j',
    description: 'my-test-account-2',
    type: 'oauth',
    oauthConfig: {
      jwksUri: 'https://dev.idp.cmf.energysector.nl/am/oauth2/connect/jwk_uri',
    },
    applicationRoles: [
      {
        id: 'd6fd297b-db32-4015-b2b6-2bcd6e14ac88',
        marketRoles: [],
        name: 'getp4:write',
      },
      {
        id: 'dedd8c45-887b-402f-aa46-501262520ccd',
        marketRoles: [],
        name: 'mpr:admin:config',
      },
      {
        id: 'de8c6043-cb3b-4b1a-bfa2-c9c6300bec3a',
        marketRoles: [],
        name: 'getp4:read',
      },
    ],
    organizations: [
      {
        externalId: '',
        id: 'eeca3b4a-f548-4ed2-970f-286166cc9b12',
        name: 'Enrgy Included B.V.',
        ean13: '88163718371893',
        marketRole: 'EDSN',
        parentId: '0bbc2424-4faa-4c5c-92d3-0c78192953c0',
        organizationType: 'subunit',
        status: 'ACTIVE',
        organizationStructure: {
          externalId: '',
          id: '',
          name: '',
          registrationNumber: '94712623',
          nameFacade: '',
          registrationNumberOrigin: '',
          marketParticipants: [],
        },
      },
    ],
  },
  {
    id: '78e9d815-aaf2-4714-a501-5ecb493f0fd0',
    name: 'nmzDeqAwbzn7Rc2j',
    description: 'Antonie',
    type: 'oauth',
    oauthConfig: {
      jwksUri: 'https://dev.idp.cmf.energysector.nl/am/oauth2/connect/jwk_uri',
    },
    applicationRoles: [
      {
        marketRoles: [],
        id: 'dedd8c45-887b-402f-aa46-501262520ccd',
        name: 'mpr:admin:config',
      },
      {
        marketRoles: [],
        id: '9fb8d8fe-295e-4724-8829-eff7ce8371f5',
        name: 'test_role_abc',
        description: 'Test',
      },
    ],
    organizations: [
      {
        externalId: '',
        id: 'eeca3b4a-f548-4ed2-970f-286166cc9b12',
        name: 'Enrgy Included B.V.',
        organizationType: 'subunit',
        status: 'ACTIVE',
        organizationStructure: {
          externalId: '',
          id: '',
          name: '',
          registrationNumber: '94712623',
          nameFacade: '',
          registrationNumberOrigin: '',
          marketParticipants: [],
        },
        ean13: '88163718371893',
        marketRole: 'EDSN',
        parentId: '0bbc2424-4faa-4c5c-92d3-0c78192953c0',
      },
    ],
  },
  {
    id: '42ea001c-5385-4c8f-af96-4d2387ce5107',
    name: 'nmzDeqAwbzn7Rc2j',
    description: 'demo-account',
    type: 'oauth',
    oauthConfig: {
      jwksUri: 'https://dev.idp.cmf.energysector.nl/am/oauth2/connect/jwk_uri',
    },
    applicationRoles: [
      {
        id: '44980820-b054-4966-86f6-a6ff6d5afe43',
        marketRoles: [],
        name: 'filegateway:ui:write',
        description: 'Write-access to the File Gateway UI.',
      },
      {
        id: 'de8c6043-cb3b-4b1a-bfa2-c9c6300bec3a',
        marketRoles: [],
        name: 'getp4:read',
      },
    ],
    organizations: [
      {
        externalId: '',
        id: 'eeca3b4a-f548-4ed2-970f-286166cc9b12',
        name: 'Enrgy Included B.V.',
        organizationType: 'subunit',
        status: 'ACTIVE',
        organizationStructure: {
          externalId: '',
          id: '',
          name: '',
          registrationNumber: '94712623',
          nameFacade: '',
          registrationNumberOrigin: '',
          marketParticipants: [],
        },
        ean13: '88163718371893',
        marketRole: 'EDSN',
        parentId: '0bbc2424-4faa-4c5c-92d3-0c78192953c0',
      },
    ],
  },
  {
    id: 'd01d723f-6e93-4933-8750-6136d8933cbb',
    name: 'nmzDeqAwbzn7Rc2j',
    description: 'test-sa',
    type: 'oauth',
    oauthConfig: {},
    applicationRoles: [],
    organizations: [
      {
        externalId: '',
        id: 'eeca3b4a-f548-4ed2-970f-286166cc9b12',
        name: 'Enrgy Included B.V.',
        organizationType: 'subunit',
        status: 'ACTIVE',
        organizationStructure: {
          externalId: '',
          id: '',
          name: '',
          registrationNumber: '94712623',
          nameFacade: '',
          registrationNumberOrigin: '',
          marketParticipants: [],
        },
        ean13: '88163718371893',
        marketRole: 'EDSN',
        parentId: '0bbc2424-4faa-4c5c-92d3-0c78192953c0',
      },
    ],
  },
  {
    id: 'bb37dc6a-0788-4123-a6de-7fa171c5177c',
    name: 'SFTP-1',
    description: 'SFTP-1',
    type: 'sftp',
    sftpConfig: {
      sshKeys: [
        {
          key: 'ssh-rsa AAAAB3NzaC3yc2EAAAADAQABAAABAQDfZxX2LXOJlo5MP2tLP4fmQyjIAcATwATFKwM6K3mtT7+LKx1jk6YlFlEcj2CFxJHHTy6LCdDqoVzL3iNcD+mDl7NbcNEHytZNJnFQ5lAHPxDVa9ZbLnmP1OlfUvsQS+jAt7yMSwd8gZ6McOJfp9ZUn+r5LLpjYkF+AMQFPsf+6lhSJaOjOTbsA39OJwlnnSO6HF3W2Om+8Bgdpa/E4En5RZTBwFCAvLaaXY3XgN76xCR24TwTWFicBHWeLdADGFXB7OBOv4y805fNGbNKOl3Yb21mG89aUQlYjobeLqImyIrrEhX36hEdMW/t6zZK/1I0QC//uLa+GjJoeuPW4WY3 ubuntu@Box',
          expires: '2024-07-17T13:09:39.635Z',
        },
      ],
    },
    applicationRoles: [
      {
        id: 'dedd8c45-887b-402f-aa46-501262520ccd',
        marketRoles: [],
        name: 'mpr:admin:config',
      },
    ],
    organizations: [
      {
        externalId: '',
        id: 'adbb132c-cf80-4941-ad78-7546ef95acc6',
        name: 'Powerhouse Inclusive B.V.',
        organizationType: 'subunit',
        status: 'ACTIVE',
        organizationStructure: {
          externalId: '',
          id: '',
          name: '',
          registrationNumber: '94712623',
          nameFacade: '',
          registrationNumberOrigin: '',
          marketParticipants: [],
        },
        ean13: '1938287462810',
        marketRole: 'LV',
        parentId: 'dc9397d9-000d-480d-8709-05179db21d94',
      },
    ],
  },
];
